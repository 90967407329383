<template>
  <svg
    width="23"
    height="27"
    viewBox="0 0 23 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="w-5 h-6"
  >
    <path
      d="M1 25.5C1 22 1 21 1 18.5C1 16 3.5 14.5 5 14"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path d="M6.5 25.5C6.5 22 6.5 23.5 6.5 21" stroke-width="1.5" stroke-linecap="round" />
    <path
      d="M22 25.5C22 22 22 21 22 18.5C22 16 19.5 14.5 18 14"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path d="M16.5 25.5C16.5 22 16.5 23.5 16.5 21" stroke-width="1.5" stroke-linecap="round" />
    <circle cx="11.5" cy="6.5" r="5.75" stroke-width="1.5" />
  </svg>
</template>
